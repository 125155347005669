import SectionTitle from "../../components/Common/SectionTitle";
import SingleServiceTwo from "../../components/Service/SingleServiceTwo";

// Service Image Icons
import mainIcon1 from "../../assets/img/service/style2/main-img/1.png";
import mainIcon2 from "../../assets/img/service/style2/main-img/2.png";
import mainIcon3 from "../../assets/img/service/style2/main-img/3.png";
import mainIcon4 from "../../assets/img/service/style2/main-img/4.png";
import mainIcon5 from "../../assets/img/service/style2/main-img/5.png";
import mainIcon6 from "../../assets/img/service/style2/main-img/6.png";
import mainIcon100 from "../../assets/img/service/style2/main-img/100.png";
import mainIcon14 from "../../assets/img/service/style2/main-img/14.png";
import mainIcon101 from "../../assets/img/service/style2/main-img/101.png";

const WhyChoose = () => {
  return (
    <div id="rs-service" className="rs-services style3 pt-80 pb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleServiceTwo
              itemClass="services-item"
              prefix="01"
              mainIcon={mainIcon1}
              hoverIcon={mainIcon1}
              Title="Client-Centric Approach"
              serviceURL="software-development"
              Text="It’s you, our client, who is at the center of our constantly developing web services. This client-centric approach is our greatest strength.  
                            "
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleServiceTwo
              itemClass="services-item pink-bg"
              prefix="02"
              mainIcon={mainIcon2}
              hoverIcon={mainIcon2}
              Title="One-stop Solution"
              serviceURL="web-development"
              Text="It’s so exhausting to find different services for each of your development needs. 
                           Our expertise makes us your one-stop solution. Sit back and watch us code!                                                        
                           "
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleServiceTwo
              itemClass="services-item aqua-bg"
              prefix="03"
              mainIcon={mainIcon3}
              hoverIcon={mainIcon3}
              Title="We brainstorm a lot!"
              serviceURL="analytic-solutions"
              Text="Our unique applications ooze from our brainstorming instincts! New ideas and suggestions sprout from our free work culture that involves open-ended discussions."
            />
          </div>

          <div className="col-lg-3 col-md-6 mb-30">
            <SingleServiceTwo
              itemClass="services-item purple-bg"
              prefix="04"
              mainIcon={mainIcon5}
              hoverIcon={mainIcon5}
              Title="Maximum Velocity"
              serviceURL="product-&-design"
              Text="Our technically balanced app development process lets us help our clients with maximum velocity. We use agile methods to get you ahead in your digital endeavors. 
                            "
            />
          </div>
          <div className="col-lg-6 col-md-6 mb-30">
            <SectionTitle
              sectionClass="sec-title2 text-center pt-30 pb-10"
              subtitleClass="sub-text style-bg"
              subtitle="Why Us"
              titleClass="title testi-title text-white"
              title="How We Put Our Stamp on Web Development Services"
              descClass="desc w-80"
              description="You get our undivided attention as we focus on providing jaw-dropping results that suit your brand or business. We brainstorm and figure out what makes your product stand out from the ocean of websites, apps, and UI/UX designs.
                            You are one among us, so we proactively communicate our process with you. So, “best” is what we do and what you get! Ta-da, it’s a win-win!
                            "
              effectClass="heading-line"
            />
          </div>
          <div className="col-lg-3 col-md-6 mb-30">
            <SingleServiceTwo
              itemClass="services-item green-bg"
              prefix="05"
              mainIcon={mainIcon6}
              hoverIcon={mainIcon6}
              Title="Value for Money!"
              serviceURL="database-administrator"
              Text="We come up with the best quote for your app and web requirements. What you get is worth every dime, plus we have no hidden costs! We value your hard-earned money. "
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleServiceTwo
              itemClass="services-item paste-bg"
              prefix="06"
              mainIcon={mainIcon4}
              hoverIcon={mainIcon4}
              Title="Believe in Innovation!"
              serviceURL="clould-&-devOps"
              Text="
                            Our belief in innovations has led us to outstanding results. The Innovative ideation triggers us to walk the extra mile to provide unique digital solutions to you.
                            "
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleServiceTwo
              itemClass="services-item"
              prefix="07"
              mainIcon={mainIcon100}
              hoverIcon={mainIcon100}
              Title="Team Spirit"
              Text="Our creative designer teams up with our expert developers to surprise you and build trust at the same time with the peculiar product and app launch which fits your business. 
                            "
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleServiceTwo
              itemClass="services-item pink-bg"
              prefix="08"
              mainIcon={mainIcon14}
              hoverIcon={mainIcon14}
              Title="Quality Service"
              Text="We know that you will cling to us until we cling to quality. We boast top-notch apps, designs, and web development services. Our happy clients reflect who we are.
                            "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
