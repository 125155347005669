import React from "react";
import { useParams } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import BlogDetailsMain from "../../components/BlogDetails";
import HeaderStyleThree from "../../components/Layout/Header/HeaderStyleThree";
import bannerbg from "../../assets/img/banner/banner-6.jpg";

const BlogDetails = () => {


  return (
    <div>
    
      {/* Display the blog details here */}

      <HeaderStyleThree parentMenu="blog" activeMenu="/blog-details" />

      <SiteBreadcrumb
        pageTitle="Why Offshore Staff Augmentation Makes Sense
"
        titleClass="page-title new-title pb-10"
        pageCategory="It Services"
        pageName="Why Offshore Staff Augmentation Makes Sense
"
        breadcrumbsImg={bannerbg}
      />

      {/* Blog Main */}
      <BlogDetailsMain />
      {/* Blog Main End */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
      <Footer />

      <SearchModal />
    </div>
  );
};

export default BlogDetails;
