import React, { Component, useState } from "react";
import SearchWidget from "../Widget/SearchWidget";
import RecentPostWidget from "../Widget/RecentPostWidget";
import CategoriesWidget from "../Widget/CategoriesWidget";
import SinglePost from "./SinglePost";

import { data } from "./data";

const BlogMain = () => {
  const [posts, setPosts] = useState(data);

  const [displayedPosts, setDisplayedPosts] = useState(posts.slice(0, 3));
  const [postIndex, setPostIndex] = useState(3); // Start from 3 since we initially display the first 3 posts

  // Function to load more posts
  const MorePost = () => {
    console.log("clicked");
    const nextPosts = posts.slice(postIndex, postIndex + 2); // Get next 2 posts based on the current index
    setDisplayedPosts([...displayedPosts, ...nextPosts]); // Append new posts to the displayed list
    setPostIndex(postIndex + 2); // Update the index to show the next batch of posts
  };
  console.log(postIndex);

  return (
    <div className="rs-blog rs-inner-blog pt-120 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 order-last">
            <div className="widget-area">
              {/* <SearchWidget />
              <RecentPostWidget />
              <CategoriesWidget /> */}
            </div>
          </div>
          <div className="col-lg-8 pr-34 md-pr-14">
            {displayedPosts.map((post) => (
              <SinglePost {...post} />
            ))}
            {/* { postIndex < posts.length && <button onClick={MorePost}>More Post</button>} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogMain;
