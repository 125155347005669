import blogImg1 from "../../assets/img/blog/inner/5.jpg";
import blogImg2 from "../../assets/img/blog/inner/2.jpg";
import blogImg3 from "../../assets/img/blog/inner/3.jpg";
import blogImg4 from "../../assets/img/blog/inner/4.jpg";
import blogImg5 from "../../assets/img/blog/inner/5.jpg";
import blogImg6 from "../../assets/img/blog/inner/6.jpg";
import blogImg7 from "../../assets/img/blog/inner/7.jpg";
import blogImg8 from "../../assets/img/blog/inner/8.jpg";
import blogImg9 from "../../assets/img/blog/inner/9.jpg";
import blogImg10 from "../../assets/img/blog/inner/10.jpg";

export const data = [
  {
    id: 1,
    blogImage: blogImg1,
    blogCategory: "Application Testing",
    blogPublishedDate: "December 12, 2024",
    blogTitle: "Why Offshore Staff Augmentation Makes Sense",
    blogDesc:
      "At Metaqualt Software, we’ve seen how this approach helps businesses scale faster, reduce costs, and access top talent. Here’s why it works and how Metaqualt can be the perfect par...",
    blogButtonClass: "blog-button inner-blog",
  },
  
  
  
];
